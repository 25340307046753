import React, { useState } from 'react';

import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Dropdown from '../../../Molecules/Dropdown/Dropdown';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';

import './styles.scss';

const classNames = require('classnames');

const Contact = ({ data, pageContext }) => {
  const { getImage } = useMedias();

  const { locale } = pageContext;
  const [contactFormData] = useState(data.contactForm.edges[0].node);
  const imagesArray = data.allImages.edges;
  const metaTags = contactFormData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  let pagesContact = [];

  contactFormData.relationships.field_contact_pages.forEach((item, i) => {
    pagesContact.push({
      id: i,
      label: item.field_title.processed,
      link: item.field_link.url,
    });
  });

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={contactFormData?.field_metatag?.title ? contactFormData?.field_metatag?.title : metaTitle}
        description={contactFormData?.field_metatag?.description ? contactFormData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_contact')}>
        <PageBanner
          visuel={getImage(
            imagesArray,
            contactFormData.relationships?.field_image?.drupal_internal__mid
          )}
          alt={contactFormData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={false}
          backgroundColor="color_dark_bleu"
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                // parentPage: {title: 'Acceuil', url: '/'},
                currentPage: { title: 'Contact', url: '' },
                locale,
              }}
            />
            <TitlePage color="color_white" title={contactFormData.title} />
          </div>
        </PageBanner>
        <div className="contact_form_selector">
          <h2>{contactFormData.field_form_title}</h2>
          <Dropdown
            customClass="dropdown-questions-footer"
            list={pagesContact}
          ></Dropdown>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MainContactTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    contactForm: allNodePageContact(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          langcode
          field_form_title
          title
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_contact_pages {
              field_link {
                uri
                url
              }
              field_title {
                processed
              }
            }
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Contact;
